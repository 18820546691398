<template>
  <div
    v-editable="blok"
    class="flex flex-col items-center justify-center px-10 py-5 lg:flex-row lg:justify-start"
    :style="{ backgroundColor: blok.background_color || 'transparent' }"
  >
    <div
      v-if="blok.headline"
      class="mr-auto w-full text-center lg:w-auto lg:text-left"
      :class="[headlineClassNames]"
      :style="{ color: blok.headline_color || 'inherit' }"
    >
      <RichTextRenderer :document="blok.headline" />
    </div>
    <div
      class="-ml-6 flex shrink-0 flex-col items-center justify-center md:flex-row"
    >
      <FimButton
        v-for="(button, i) in blok.buttons"
        :id="'hero-' + idPrefix + button._uid"
        :key="i"
        class="ml-6 mt-4 lg:mt-0"
        is-link
        :to="ctaURLHandler(button, $currentShop)"
        :target="button.cta_target_blank ? '_blank' : '_self'"
        :type="toButtonType(button.cta_variant)"
        :data-testid="`button_${button._uid}`"
        @mousedown="trackClick"
      >
        {{ button.cta_label }}
      </FimButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SbButtonBox } from '../types/storyblok.gen'
import { getHeadlineClassNames } from '~/storyblok/utils/cssClassesHelpers'
import { ctaURLHandler } from '~/storyblok/utils/links'

const props = defineProps({
  blok: {
    type: Object as PropType<SbButtonBox>,
    required: true,
  },
  idPrefix: {
    type: String as PropType<string>,
    default: '',
  },
})

const { trackButtonClick } = await useTrackingEvents()
const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)
}

const headlineClassNames = computed(() =>
  getHeadlineClassNames(props.blok.headline_look),
)

const $currentShop = useCurrentShop()
</script>
